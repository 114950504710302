import "../index.css";
import { Canvas } from "@react-three/fiber";
import { Scene } from "../Scene";
import { StatsGl } from "@react-three/drei";
import { LoadingScreen } from "../components/LoadingScreen";
import { useEffect, useState } from "react";

const entranceAudio = new Audio("./audios/entrance.mp3");
const ambienceAudio = new Audio("./audios/forest_and_winds.mp3")

export default function Home() {
  const [start, setStart] = useState(false);

  useEffect(() => {
    if (start) {
      entranceAudio.onended = () => {
        ambienceAudio.loop = true;
        ambienceAudio.play();
      };
      entranceAudio.play();
    }
  }, [start]);

  return (
    <div id="r3f-wrapper">
      <div className="brand-information">Powered by <a href="https://develobe.id/" target="_blank" rel="noreferrer" className="action-link" id="btn-develobe-brand">DEVELOBE.ID</a> | <a href="/credits" target="_blank" className="action-link" id="btn-credits">Assets Credits</a></div>
      <a href={process.env.REACT_APP_TARGET_URL} target="_blank" rel="noreferrer" id="btn-buy-action" className="hidden">Buy Now</a>

      <Canvas gl={{ localClippingEnabled: true }}>
        {/* <StatsGl /> */}
        <Scene started={start}/>
      </Canvas>

      <LoadingScreen started={start} onStarted={() => setStart(true)} />
    </div>
  );
}