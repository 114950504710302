import { Cloud, Clouds, Stars } from "@react-three/drei";
import { useRef } from "react";
import { MeshLambertMaterial } from "three";

export function Sky(){
  const config = {
    seed: 1,
    segments: 40,
    volume: 3,
    opacity: 0.8,
    fade: 10,
    growth: 3,
    speed: 1,
    bounds: [4, 1, 3],
    position: [0, -1, 0],
    color: "#ffccdd"
  };

  const cloudRef = useRef();

  return (
    <>
      <group>
        <Clouds material={MeshLambertMaterial} limit={400} >
          <Cloud 
            ref={cloudRef}
            position={config.position}
            bounds={config.bounds}
            color={config.color}
            seed={config.seed}
            segments={config.segments}
            volume={config.volume}
            opacity={config.opacity}
            fade={config.fade}
            growth={config.growth}
            speed={config.speed}/>

          {/* <Cloud 
            concentrate="outside" 
            growth={100} 
            color="#ffccdd" 
            opacity={1.25} 
            seed={0.3} 
            bounds={200} 
            volume={200} /> */}
        </Clouds>
      </group>

      <Stars radius={100} depth={40} count={1000} factor={10} saturation={0} fade speed={2} />
    </>
  );
}
