import { useGLTF } from "@react-three/drei";
import { MathUtils } from "three/src/math/MathUtils";

export function Book({ innerRef }) {
  // Prepare variable
  const { scene, nodes, materials } = useGLTF("/models/book_with_cover.glb");
  const position = [0, 0, 0];
  const rotation = [0, 0, 0];
  const scale = [6, 6, 6];

  // Set position of the book
  position[0] = -0.4 * scale[0];
  position[1] = 6.5;
  position[2] = 0.3;

  // Rotate the book to face the camera
  rotation[0] = MathUtils.degToRad(90);

  return (
    <group ref={innerRef} name="book">
      <primitive object={scene} position={position} rotation={rotation} scale={scale}/>
    </group>
  );
}
