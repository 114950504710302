import { useProgress } from "@react-three/drei";

export const LoadingScreen = ({ started, onStarted }) => {
  const { progress } = useProgress();

  return (
    <div className={`loadingScreen ${started ? "loadingScreen--started" : ""}`}>
      <img src="/images/sunflower.png" className="sunflowerLoader" />
      {progress < 100 && 
        <div className="loadingLabel">Karya sedang disiapkan... { Math.floor(progress) }%</div>
      }
      {progress == 100 && 
        <button
          id="btn-start-website"
          onClick={onStarted}
          className="loadingButton">
          Klik di sini untuk melihat karya
        </button>
      }
    </div>
  );
};
