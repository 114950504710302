import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Credits from "./pages/Credits";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/credits" element={<Credits/>} />
        <Route path="*" element={<Home/>} />
      </Routes>
    </>
  );
}

export default App;
