import { Sparkles } from "@react-three/drei";

export function Insects() {
  return (
    <mesh position={[0, 0, 0]}>
      <boxGeometry args={[0.1,0.1,0.1]}/>
      <meshBasicMaterial visible={false} transparent={true} />

      <Sparkles 
        count={20} 
        scale={6 * 2} 
        size={20} 
        speed={4}
        color="white"/>
    </mesh>
  );
}