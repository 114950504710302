import { useFrame } from "@react-three/fiber";
import { useRef } from "react";

export function ClayPot() {
  // Prepare variable
  const potRef = useRef();

  // Frame counter
  var frameCount = 0;

  // Floating direction
  // true = going up
  // false = going down
  var floatDirection = false;

  // Default threshold
  const initialThreshold = 80;

  // Frame threshold for floating animation
  var frameThreshold = initialThreshold;

  // Floating animation speed
  const floatingAnimation = 0.005;
  
  useFrame(() => {
    if (potRef){
      // Check floating direction
      if (floatDirection){
        // Going up
        potRef.current.position.y += floatingAnimation;
      } else {
        // Going down
        potRef.current.position.y += -floatingAnimation;
      }

      // Frame counter
      frameCount++;

      // Check frame threshold
      if (frameCount >= frameThreshold){
        // Change direction
        floatDirection = !floatDirection;

        // Reset frame counter
        frameCount = 0;

        // After first animation, double the threshold to make full floating animation
        if (frameThreshold == initialThreshold){
          frameThreshold = 2 * initialThreshold;
        }
      }
    }
  })

  return (
    <group ref={potRef} name="clay-pot" position={[0, 0, 0]}>
      <mesh position={[0, 0, 0]} >
        <cylinderGeometry args={[3, 3, 0.2, 32]}/>
        <meshStandardMaterial color={'#3B1919'} />
      </mesh>

      <mesh position={[0, -0.3, 0]} >
        <cylinderGeometry args={[3, 2, 0.5, 8]}/>
        <meshStandardMaterial color={'#3B1919'} />
      </mesh>
    </group>
  );
}
