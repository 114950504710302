import { useFrame } from "@react-three/fiber";
import { useRef } from "react";
import { DoubleSide, Quaternion, Vector3 } from "three";
import { MathUtils } from "three/src/math/MathUtils";

export function Birds() {
  const pivotGroupRef = useRef();
  const flyingSpeed = [
    0.007,
    0.012,
    0.008
  ];

  useFrame(() => {
    if (pivotGroupRef){
      for (let index = 0; index < pivotGroupRef.current.children.length; index++) {
        pivotGroupRef.current.children[index].rotation.y -= flyingSpeed[index];
        pivotGroupRef.current.children[index].rotation.z += flyingSpeed[index];
      }
    }
  })

  return (
    <group ref={pivotGroupRef}>
      <object3D>
        <Bird props={{
          color: "#A52A2A",
          position: [0, 5, 5],
          wingAnimationSpeed: 0.1,
          scale: [1, 1, 1],
        }} />
      </object3D>

      <object3D>
        <Bird props={{
          color: "#711313",
          position: [0, 4, 5],
          wingAnimationSpeed: 0.15,
          scale: [1.5, 1.5, 1.5],
        }} />
      </object3D>
      
      <object3D>
        <Bird props={{
          color: "#711313",
          position: [0, 6, 8],
          wingAnimationSpeed: 0.2,
          scale: [1.3, 1.3, 1.3],
        }} />
      </object3D>
    </group>
  );
}

function Bird({ props }){
  const birdRef = useRef();
  const wing1Ref = useRef();
  const wing2Ref = useRef();

  var frameCount = 0;
  var wingDirection = true;
  const wingInitialThreshold = 8;
  var wingFrameThreshold = wingInitialThreshold;

  useFrame(() => {
    // Check wing direction
    if (wingDirection){
      wing1Ref.current.rotation.y += props.wingAnimationSpeed;
      wing2Ref.current.rotation.y -= props.wingAnimationSpeed;
    } else {
      wing1Ref.current.rotation.y -= props.wingAnimationSpeed;
      wing2Ref.current.rotation.y += props.wingAnimationSpeed;
    }

    // Count the frame
    frameCount++;

    // Check frame threshold
    if (frameCount >= wingFrameThreshold){
      // Toggle swing direction
      wingDirection = !wingDirection;

      // Reset frame counter
      frameCount = 0;

      // After first animation, double the threshold to make full swing animation
      if (wingFrameThreshold == wingInitialThreshold){
        wingFrameThreshold = 2 * wingInitialThreshold;
      }
    }
  })
  
  return (
    <group ref={birdRef} name="birds"
      position={props.position}
      rotation={[0, 0, MathUtils.degToRad(90)]}
      scale={props.scale}>
      <mesh position={[0, 0, 0]} scale={[0.03, 0.4, 0]}>
        <shapeGeometry />
        <meshLambertMaterial side={DoubleSide} color={props.color} />
      </mesh>

      <object3D ref={wing1Ref}>
        <mesh position={[0, 0, 0.1]} scale={[0.2, 0.2, 0]} rotation={[MathUtils.degToRad(90), MathUtils.degToRad(90), 0]}>
          <shapeGeometry />
          <meshLambertMaterial side={DoubleSide} color={props.color} />
        </mesh>
      </object3D>

      <object3D ref={wing2Ref}>
        <mesh position={[0, 0, -0.1]} scale={[0.2, 0.2, 0]} rotation={[-MathUtils.degToRad(90), MathUtils.degToRad(90), 0]}>
          <shapeGeometry />
          <meshLambertMaterial side={DoubleSide} color={props.color} />
        </mesh>
      </object3D>
    </group>
  );
}
