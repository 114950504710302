import {
  OrbitControls,
  PerspectiveCamera
} from "@react-three/drei";
import { Suspense, useRef, useState } from "react";
import { Book } from "./components/Book";
import { SunflowerField } from "./components/SunflowerField";
import { ClayPot } from "./components/ClayPot";
import { BuyAction } from "./components/BuyAction";
import { useFrame } from "@react-three/fiber";
import { Sky } from "./components/Sky";
import { Birds } from "./components/Birds";
import { Insects } from "./components/Insects";

export function Scene({ started }){
  const lightRef = useRef();
  const bookRef = useRef();
  const cameraRef = useRef();
  const orbitRef = useRef();
  let orbitControl = false;

  useFrame((state) => {
    state.camera.lookAt(0, 4.5, 0);

    if (cameraRef.current && started){
      if (cameraRef.current.position.x > 0 && !orbitControl){
        cameraRef.current.position.subScalar(0.3);
      } else {
        orbitControl = true;
        orbitRef.current.enablePan = true;
        orbitRef.current.enableZoom = true;
        orbitRef.current.enableRotate = true;
      }
    }
  })

  return (
    <Suspense fallback={null}>
      <Sky />

      <directionalLight 
        ref={lightRef}
        color="white" 
        position={[5, 10, 20]} 
        intensity={0.5} 
        />

      <hemisphereLight color="orange" intensity={5} />

      <PerspectiveCamera ref={cameraRef} makeDefault position={[20, 24.5, 35]} fov={60} />
      <OrbitControls 
        ref={orbitRef}
        enablePan={false}
        enableRotate={false}
        enableZoom={false}
        minPolarAngle={Math.PI / 8}
        maxPolarAngle={Math.PI / 2}
        minDistance={10}
        maxDistance={50}/>
      
      <Book innerRef={bookRef} />
      <BuyAction />
      <SunflowerField />
      <ClayPot />
      <Birds />
      <Insects />
    </Suspense>
  );
}