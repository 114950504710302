import { MathUtils } from "three/src/math/MathUtils";
import { Sampler, useGLTF } from "@react-three/drei";
import { useEffect, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { Matrix4, Plane, Quaternion, Vector3 } from "three";

export function SunflowerField() {
  const range = 60;
  const { nodes, materials } = useGLTF("/models/sunflower.glb");
  const sunflowerRef = useRef();
  const geometryRef = useRef();

  // Instance matrix
  var matrix = new Matrix4();
  
  // Swing direction for sunflower
  var swingDirection = true;

  // Floating direction for sunflower
  // true = going up
  // false = going down
  var floatingDirection = false;
  
  // Frame counter
  var swingFrameCount = 0;
  var floatingFrameCount = 0;

  // Initial frame threshold for first animation
  const swingInitialThreshold = 80;
  const floatingInitialThreshold = 80;

  // Frame threshold for swing animation
  var swingFrameThreshold = swingInitialThreshold;
  var floatingFrameThreshold = floatingInitialThreshold;

  // Swing animation speed
  const swingAnimation = 0.0025;
  const floatingAnimation = 0.005;

  // Local planes for clipping sunflower in underground
  const localPlane = new Plane(new Vector3(0, 1, 0), 0);

  useFrame((state) => {
    if (sunflowerRef){
      for (let index = 0; index < range; index++) {
        // Get instance matrix from instances array
        sunflowerRef.current.getMatrixAt(index, matrix);

        // Prepare variable
        var position = new Vector3();
        var currentQuaternion = new Quaternion();
        var scale = new Vector3();
        
        // Get specific data from matrix
        matrix.decompose(position, currentQuaternion, scale);
        
        // Set rotate direction using quaternion
        const quaternionY = new Quaternion();

        // Check swing direction
        if (swingDirection){
          // swing direction to right
          quaternionY.setFromAxisAngle(new Vector3(1, 0, 0).normalize(), -swingAnimation);
        } else {
          // swing direction to left
          quaternionY.setFromAxisAngle(new Vector3(1, 0, 0).normalize(), swingAnimation);
        }

        // Check floating direction
        if (floatingDirection){
          // Going up
          position.z += floatingAnimation;
        } else {
          // Going down
          position.z -= floatingAnimation;
        }

        // Rotate instance object using quaternion
        currentQuaternion.multiplyQuaternions(quaternionY, currentQuaternion);
        
        // Update instance matrix data
        matrix.compose(position, currentQuaternion, scale);
        sunflowerRef.current.setMatrixAt(index, matrix);
        sunflowerRef.current.instanceMatrix.needsUpdate = true; 
      }

      // Count frame for swing direction
      swingFrameCount++;
      
      // Check frame threshold
      if (swingFrameCount >= swingFrameThreshold){
        // Toggle swing direction
        swingDirection = !swingDirection;

        // Reset frame counter
        swingFrameCount = 0;

        // After first animation, double the threshold to make full swing animation
        if (swingFrameThreshold == swingInitialThreshold){
          swingFrameThreshold = 2 * swingInitialThreshold;
        }
      }

      // Set floating animation for clipping plane
      if (floatingDirection){
        // Clipping going up
        localPlane.constant -= floatingAnimation;
      } else {
        // Clipping going down
        localPlane.constant += floatingAnimation;
      }

      // Count frame for floating direction
      floatingFrameCount++;
      
      // Check frame threshold
      if (floatingFrameCount >= floatingFrameThreshold){
        // Toggle floating direction
        floatingDirection = !floatingDirection;

        // Reset frame counter
        floatingFrameCount = 0;

        // After first animation, double the threshold to make full floating animation
        if (floatingFrameThreshold == floatingInitialThreshold){
          floatingFrameThreshold = 2 * floatingInitialThreshold;
        }
      }
    }
  })

  useEffect(() => {
    // Clipping for part of the sunflower in underground
    materials.lambert2SG.clippingPlanes = [localPlane];
  });

  return (
    <group>
      <mesh ref={geometryRef}
        rotation={[MathUtils.degToRad(-90), 0, 0]}>
        <circleGeometry args={[3, 32]} />
        <meshStandardMaterial transparent={true} opacity={0} />
      </mesh>

      <instancedMesh 
        ref={sunflowerRef} 
        args={[nodes.Object_2.geometry, materials.lambert2SG, range]} 
        rotation={[MathUtils.degToRad(-90), 0, MathUtils.degToRad(-90)]}>
      </instancedMesh>

      <Sampler
        mesh={geometryRef}
        instances={sunflowerRef}
        transform={({ position, normal, dummy: object }) => {
          object.scale.setScalar((Math.random() * 0.005) + 0.01);
          object.position.set(position.x, position.y, -0.6);
          object.rotation.set(0, 0, MathUtils.degToRad((Math.random() * 30)));

          object.updateMatrix()
          return object;
        }}
        count={range}
      />
    </group>
  );
}
