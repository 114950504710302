import "../index.css";

export default function Credits() {
  return (
    <div className="credits-wrapper">
      Special thanks to :
      <ul>
        <li>
          "Book" (https://skfb.ly/VTQn) by Eric Fan is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
        </li>

        <li>
          "Sunflower" (https://skfb.ly/69oAN) by zvanstone is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
        </li>

        <li>
          <a href="https://www.flaticon.com/free-icons/sunflower" title="sunflower icons">Sunflower icons created by Freepik - Flaticon</a>
        </li>

        <li>
          Sound Effect from <a href="https://pixabay.com/sound-effects/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=6881">Pixabay</a>
        </li>
      </ul>
    </div>
  );
}
