import { Center, Text3D } from "@react-three/drei"
import { useFrame } from "@react-three/fiber";
import { useRef, useState } from "react";
import { openInNewTab } from "../systems/openInNewTab"

export function BuyAction() {
  const meshRef = useRef();
  const [meshHovered, setMeshHover] = useState(false);

  const fontProps = { 
    font: '/fonts/Optimer_Regular.json', 
    size: 0.3, 
    height: 0.05
  }

  useFrame(() => {
    meshRef.current.material.color.set(meshHovered ? 'green': 'brown');
  });

  return (
    <mesh 
      ref={meshRef}
      position={[0, 3.6, 0]}
      onPointerOver={(e) => (e.stopPropagation(), setMeshHover(true))}
      onPointerOut={() => setMeshHover(false)}
      onClick={() => {
        document.getElementById("btn-buy-action").click();
      }}>

      <boxGeometry args={[2.5, 0.8, 0.2]} />
      <meshStandardMaterial color={"brown"} />
      
      <Center position={[0, 0, 0.15]}>
        <Text3D {...fontProps}>
          Buy Now
          <meshBasicMaterial />
        </Text3D>
      </Center>
    </mesh>
  );
}
